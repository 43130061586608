import React, { useState, useRef, useEffect } from "react";
import ChatMessage from "./ChatMessage";
import loadingring from "./loader-ring.gif";
import axios from "axios";

const ChatWindow = () => {
  const [messages, setMessages] = useState(() => {
    // Retrieve messages from session storage if available
    const storedMessages = sessionStorage.getItem("chatMessages");
    return storedMessages
      ? JSON.parse(storedMessages)
      : [{ text: "Hi, how can I help you today?", isUser: false }];
  });
  const [newMessage, setNewMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    setLoader(true);
    const updatedMessages = [...messages, { text: newMessage, isUser: true }];
    setMessages(updatedMessages);
    setNewMessage("");

    try {
      const response = await axios.post(
        `https://applications.digitalexpressway.net/api/query/chat`,
        { question: newMessage },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.status === 200) setLoader(false);
      const aiResponse = response.data.data;
      setMessages([...updatedMessages, { text: aiResponse, isUser: false }]);
    } catch (error) {
      setLoader(false);
      console.error("Error sending message to AI:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    // Auto resize the textarea based on its content
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newMessage]);

  useEffect(() => {
    // Update session storage whenever messages state changes
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
    // Scroll to the bottom when new messages are added
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-screen p-4 bg-gray-100">
      <div className="flex-1 overflow-y-auto p-4 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
        {messages.map((msg, index) => (
          <ChatMessage key={index} message={msg.text} isUser={msg.isUser} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="flex items-center p-2 border-t border-gray-300 bg-white">
        <textarea
          ref={textareaRef}
          className="flex-1 p-2 border border-gray-300 rounded-lg resize-none overflow-hidden"
          rows="1"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message here..."
          style={{ lineHeight: "1.5", minHeight: "3rem", maxHeight: "10rem" }}
        />
        {loader === false ? (
          <button
            className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
            onClick={handleSendMessage}
          >
            Send
          </button>
        ) : (
          <img src={loadingring} alt="Loading..." className="h-10" />
        )}
      </div>
    </div>
  );
};

export default ChatWindow;
