import React from 'react';
// import ChatScreen from './chat/ChatScreen';
import ChatWindow from './chat/ChatWindow';
import Admin from './admin/Admin';
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from './ErrorBoundary';

function Routeconfigue() {
  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={<ChatWindow />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </ErrorBoundary>
  );
}

export default Routeconfigue;