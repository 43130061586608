import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routeconfigue from './Routeconfigue';

function App() {
  return (
    <Router>
      <Routeconfigue />
    </Router>
  );
}

export default App;